
.quiz-card {
  height: 500px;
    .header{
        padding: 0;
        border: none;
        // height: 130px;
        img{
            width: 100%;
            height: 200px;
            object-fit: cover;
        }

        .pub-btn, .countdown{
            position: absolute;
            // background: red;
            padding: 5px 10px;
            right: 10px;
            border-radius: 5px;
            font-weight: 600;
        }
        //PUBLICATION BUTTON
        .pub-btn{
            top: 10px;
            width: 45px;
            height: 45px;
            padding: 2.5px;

            input{
                display: none;
            }

            
  .toggleWrapper {
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .2s;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #fe4551;
    box-shadow: 0 20px 20px 0 rgba(#fe4551, 0.3);
    
    &:active {
    
      box-shadow: 0 15px 15px 0 rgba(#fe4551, 0.5);
      
      .toggle {
        height: 17px;
        width: 17px;
      }
    }
    
    .toggle {
      transition: all 0.2s ease-in-out;
      height: 30px;
      width: 30px;
      background-color: transparent;
      border: 10px solid #fff;
      border-radius: 50%;
      cursor: pointer;
      
      animation: red .7s linear forwards;
    }
  }

  input:checked {
    & ~ .background {
      background-color: #f9faf7;
    }
    & + .toggleWrapper {
      background-color: #48e98a;
      box-shadow: 0 20px 20px 0 rgba(#48e98a, 0.3);
      
      &:active {
        box-shadow: 0 15px 15px 0 rgba(#48e98a, 0.5);
      }

      .toggle {
        width: 0;
        background-color: #fff;
        border-color: transparent;
        border-radius: 30px;
        animation: green .7s linear forwards !important;
      }
    }
  }
        }
//PUBLOCAATION BUTTON ENDS

        .countdown{
            top: 160px;
            background: #ffd338;
            color: #262b40;
        }
    }
    &:hover{
        transition: .1s ease-in-out;
        box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.4);
        // transform: scale(1.01);
    }

    .body{
        padding: 0;
        .header{

        .title{
          display: flex;
          justify-content: space-between;
            padding: 10px;
            background: #262b40;
            letter-spacing: 1px;
            color: #fff;

            h4, svg{
              color: #fff;
              font-size: 22px;
              align-self: center;
            }
            .options{
              .dropdown-menu{
                top: 0px;
                margin-top: 200px;
                svg{
                  color: #ccc;
                  font-size: 16px;
                  margin-right: 5px;
                }
              }
            }
        }

        .category, .qstCount{
            padding: 5px 10px;
            margin:0;
            background: none;
        }
       
      } .about{
          padding: 0 10px;
          p{
            font-size: 14px;
          }
          .bounty{

          }
          .description{
            margin: 0;
            max-height: 75px;
              text-align: justify;
              text-justify: inter-word;
          }
      }
    }

    .footer{
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        // background: red;
        padding: 10px;
        .btn{
          display: flex;
          flex-direction: row;
          padding: 0;
          justify-content: space-between;
        }
        p, button{
            font-size: 12px;
            align-self: center;
            margin: 0;
            margin-left: 5px;
        }
        button{
            border: none;
            font-size: 16px;
            padding: 5px 10px;
            border-radius: 5px;
            outline: none;
             background: #ffd338;
            color: #262b40;
        }
    }
}


@media (max-width: 768px){
    .quiz-card {
    .header{
     img{
        height: 160px;
    }
  
    .countdown{
            top: 120px;
            background: #ffd338;
            color: #262b40;
        }
  }
}

    }

.add-btn{
    position: fixed;
    bottom: 2%;
    right: 2%;
}


@keyframes red {
  0% {
    height: 30px;
    width: 0;
    border-width: 5px;
  }
  55% {
    height: 13px;
    width: 27px;
    border-width: 10px;
  }
  
  70% {
    height: 20px;
    width: 20px;
    border-width: 10px;
  }
  
  85% {
    height: 15px;
    width: 25px;
    border-width: 10px;
  }
  
  100% {
    height: 20px;
    width: 20px;
    border-width: 10px;
  }
}

@keyframes green {
  0% {
    height: 20px;
    width: 20px;
    border-width: 10px;
  }
  25%, 55%, 85% {
    height: 30px;
    width: 5px;
    border-width: 5px;
  }
  
  40%, 70%, 100% {
    height: 30px;
    width: 0;
    border-width: 5px;
  }
}












//PUBLICATION BUTTON //PUBLICATION BUTTON
        .published-btn{
            top: 10px;
            width: 45px;
            height: 45px;
            padding: 2.5px;

            input{
                display: none;
            }

            
  .toggleWrapper {
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .2s;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #fe4551;
    // box-shadow: 0 20px 20px 0 rgba(#fe4551, 0.3);
    
    &:active {
    
      // box-shadow: 0 15px 15px 0 rgba(#fe4551, 0.5);
      
      .toggle {
        height: 17px;
        width: 17px;
      }
    }
    
    .toggle {
      transition: all 0.2s ease-in-out;
      height: 30px;
      width: 30px;
      background-color: transparent;
      border: 10px solid #fff;
      border-radius: 50%;
      cursor: pointer;
      
      animation: red .7s linear forwards;
    }
  }

  input:checked {
    & ~ .background {
      background-color: #f9faf7;
    }
    & + .toggleWrapper {
      background-color: #48e98a;
      box-shadow: 0 20px 20px 0 rgba(#48e98a, 0.3);
      
      &:active {
        box-shadow: 0 15px 15px 0 rgba(#48e98a, 0.5);
      }

      .toggle {
        width: 0;
        background-color: #fff;
        border-color: transparent;
        border-radius: 30px;
        animation: green .7s linear forwards !important;
      }
    }
  }
        }
//PUBLOCAATION BUTTON ENDS


































//QUESTION MODAL

.qst-modal{
  height: 96vh;
  // max-height: 96%;

  border-radius: 10px !important;

  max-width: 100%;
      // margin: auto;
      margin-left: 1%;

  .qst-cards{
    .qst-col{
      // position: fixed;
      // width: 100%
    }
  }

  .qsts-col{
  overflow-x: scroll;

    height: 600px;
    max-height: 100%;
    overflow-y: scroll;
  }


  .set-col{
    .qst-img{
      height: 160px;
      img, iframe{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    
     .visual-type{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

    .question-card{
      padding: 5px;
      .card-header, .card-body{
        padding: 0px !important;
      }

      .card-header{
        .qst-img{
          width: 100%;
          height: 160px;
         img{
          width: 100%;
          height: 100%;
          object-fit: cover;}
        }
      }
    }


  @media (min-width: 1200px){
  .modal-xl {
      max-width: 98%;
  }

  .card-header{
        .qst-img{
          width: 100%;
          height: 220px !important;
        }
      }


}

  @media (min-width: 992px){
  .modal-lg, .modal-xl {
      max-width: 98%;
  }}

  @media (min-width: 576px){
  .modal-dialog-centered {
      min-height: calc(100% - 3.5rem);
  }}

  @media (min-width: 576px){
  .modal-dialog {
      max-width: 98%;
      margin: 1.75rem auto;
  }}
}



















//SLIDER

.slider {
  align-self: center;
  -webkit-appearance: none;
  
  width: 100%;
  height: 4px;
  padding: 0;
  background: #d3d3d3;
  border-radius: 0;
  // outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #04AA6D;
  border-radius: 4px;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #04AA6D;
  cursor: pointer;
}


.quizImg{
  // width: 100%;
  // max-width: 470px;
  // height: 200px;
  // background-color: red;
  display: flex;
  justify-content: center;
  background: rgba(0, 0, 0, 0.1);
  padding: 5px;
  border-radius: 10px;
  img{
  // width: 100% ;
  //   max-width: 470px;
    height: 100%;
    object-fit: cover;
  }
}


.iframe{
  position: relative;

  iframe{

  }
  .iframe-overlay{
    position: absolute;
    opacity: 0.05;
    height: 100%;
    width: 100%;
    background: yellow;
    z-index: 20;
    top: 0;
  }
}



.table-responsive{
  td, td p{
    font-size: 12px;
    white-space: pre-wrap;
  }
  .ansRatio{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: justify;
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    span{
      margin: 0 0 2.5px 0;
    }
  }
}