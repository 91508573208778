@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800&display=swap');

// Bootstrap mixins and functions
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/functions";

// Change variables here
@import "volt/variables";

// Bootstrap
@import "../../node_modules/bootstrap/scss/bootstrap";

// Vendor
@import "volt/vendor";

// volt mixins & functions
@import "volt/mixins";
@import "volt/functions";

// Utilities
@import "volt/reboot";
@import "volt/utilities";

// Layout
@import "volt/layout";

// Components
@import "volt/components";

// write your custom styles here!

.banned{
    background: red;
}

.react-bs-table-csv-btn{
    background: #4aa4c0 !important;
    color: #fff;
    margin: 0 10px;
}

.user-view{
    // background: red;
    ul{ 
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
    }
    .stats{
          li{
            // background: #fff;
            float: right;
            display: flex;
            flex-direction: column;
            // justify-content: space-between;
            margin: 0 5px;
            color: rgba(0, 0, 0, .7);
            letter-spacing: 1px;
            text-align: center;

            span{
                font-weight: 600;
                font-size: 22px;
            }
            // display: flex;
            // justify-content: right;   
          }
    }
    
    div.basic{
        margin-bottom: 40px;
        letter-spacing: 1px !important;
        img{
            width: 120px;
            border-radius: 5px !important;
            height: auto;
            box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
        }
        .d-block{
            align-self: flex-end;
            color: #fff;
            background: rgba(0, 0, 0, .9);
            padding: 5px 10px;
            border-radius: 5px ;
            span{
                font-size: 22px !important;
                text-align: justify;
                i{
                    margin: 2.5px
                }
            }
            .text-gray{
                font-size: 16px !important;
                color: #fff !important;
            }
        }
    }

    .others{
        ul{
            // columns: 2;
            list-style-type: disc;
        li{
            padding: 15px;
            border-radius: 10px;
            border: 1px solid rgba(0, 0, 0, .2);
            margin: 10px;
            display: flex;
            justify-content: space-between;
            span{

            }
        }
        }
    }

}


button{
    outline: none;
    margin: 5px;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, .2);
    background: #fff;
    font-size: 20px;
    font-weight: 600;
    padding: 6px 10px;
}


//CONFIRM ELEMENT
.confirm-element{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    text-align: center;
    h3{
        // flex-grow: 1;
        margin: 10px 0;
    }
    .btn-div{
        margin-top: 20px;
        .btn-cancel, .btn-submit{

        }
    }


}


//
.sidebar-icon{
    width: 50px !important;
    height: 50px !important;
}


//Transaction consignment
.view-cnt{
    position: relative;

    .transactions-cnt, .withdrawal-cnt{
        transition: .5s ease-in-out;
    }
    .transactions-cnt{
        transform: translateX(-200%);
        &.show{
            transform: translateX(0%);
        }
    }

    .withdrawal-cnt{
        position: absolute;
        z-index: 2;
        top: 0;
        transform: translateX(200%);
        &.show{
            transform: translateX(0%);
        }
       
    }
}



//LOG ACTYIVITY
.log-activity{
    height: 200px;
    overflow-y: scroll;
    padding: 10px 20px;
    p{
        display: flex;
        letter-spacing: 1px;
        flex-direction: column;
        margin: 5px 0;
        font-size: 12px;
        border-bottom: 1px solid rgba(0, 0, 0, .2);
        .type{
            font-weight: 600;
        }
        .details{
            color: rgba(0, 0, 0, .8);
        }
        .date{
            text-align: right;
        }
    }
}




















